@import url(https://rsms.me/inter/inter.css);
html {
  background-color: #3030F2;
  zoom: .95;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.asset-body {
  display: flex;
  width: 24rem;
  height: 24rem;
  /* border: 1px solid red; */
  margin: .5rem 0 0 0;
  justify-content: space-between;
  flex-direction: column;
}

.asset-title {
  font-size: 2.5rem;
  padding: 2.5rem 2.5rem 0;
  line-height: 40px;
  text-align: left;
  max-width: 400px;
}

.asset-emoji {
  text-align: right;
  padding: 0 1rem 1rem 0;
  font-size: 5rem;
  /* border: 1px solid red; */
}
.button-body {
  /* background-color: #000; */
  border-radius: 3rem;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  margin: .25rem;
  min-width: 5rem;
  padding: 1rem;
  border: 1px solid #000;
  margin: 4px;
  font-weight: bolder;
  outline: none;
}
.spin-button {
  background-color: #000;
  border-radius: 3rem;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  margin: .25rem;
  min-width: 5rem;
  padding: 1rem;
  border: none;
  font-weight: bolder;
  outline: none;
}
.app-wheel {
  width: 100%;
  height: 20rem;
  position: absolute;
  bottom: 0;
  /* transform: translate(-25rem, 56rem) rotate(180deg); */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.wheel-helper {
  transform: translate(0, 0) rotate(180deg);
}

.wheel-body {
  background-color: #fff;
  width: 75rem;
  height: 75rem;
  border-radius: 50%;
  border: 1rem dashed #E2E2FD;
  /* box-shadow: 0 0 32px #E2E2FD */
}
/* FONT */

/* APP */

.app {
  font-family: 'Inter var', sans-serif;
  font-weight: 700;
  letter-spacing: -.1em;
  background-color: #3030F2;
}

.app-logo {
  width: 2.6rem;
  pointer-events: none;
  padding: 1.2rem 1rem 1.25rem 1.25rem;
  /* border-bottom: 1px solid red; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.app-header {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(70, 70, 255);
  /* border: 1px solid red; */
}

.app-title {
  display: flex;
  align-items: center;
}

.app-name {
  font-weight: bolder;
  font-size: 1rem;
  line-height: .9rem;
  letter-spacing: -0.025rem;
}

.app-subname {
  color: #ACACFF;
  font-size: 1rem;
  font-weight: bold;
}

.app-toggle {
  text-align: right;
  /* border: 1px solid red; */
}

.icon-toggle {
  width: 2rem;
  padding: 1.5rem 1.5rem 1.5rem 0;
  /* height: 2rem; */
}

.app-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-footer {
  /* background-color: white; */
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  /* height: 50vh; */
  text-align: center;
  padding: 2rem 0;
}

.app-people {
  display: flex;
  font-size: 2rem;
  overflow: scroll;
  width: 100%;
  display: flex;
  justify-content: center;
}

.app-people>button {
  display: inline-block;
  font-size: 1.5rem;
  text-align: center;
  width: .5rem;
  padding: .5rem;
}

.app-link {
  color: #61dafb;
}

/* KEYFRAMES */

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
