.app-wheel {
  width: 100%;
  height: 20rem;
  position: absolute;
  bottom: 0;
  /* transform: translate(-25rem, 56rem) rotate(180deg); */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.wheel-helper {
  transform: translate(0, 0) rotate(180deg);
}

.wheel-body {
  background-color: #fff;
  width: 75rem;
  height: 75rem;
  border-radius: 50%;
  border: 1rem dashed #E2E2FD;
  /* box-shadow: 0 0 32px #E2E2FD */
}