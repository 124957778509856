/* FONT */

@import url('https://rsms.me/inter/inter.css');

/* APP */

.app {
  font-family: 'Inter var', sans-serif;
  font-weight: 700;
  letter-spacing: -.1em;
  background-color: #3030F2;
}

.app-logo {
  width: 2.6rem;
  pointer-events: none;
  padding: 1.2rem 1rem 1.25rem 1.25rem;
  /* border-bottom: 1px solid red; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.app-header {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(70, 70, 255);
  /* border: 1px solid red; */
}

.app-title {
  display: flex;
  align-items: center;
}

.app-name {
  font-weight: bolder;
  font-size: 1rem;
  line-height: .9rem;
  letter-spacing: -0.025rem;
}

.app-subname {
  color: #ACACFF;
  font-size: 1rem;
  font-weight: bold;
}

.app-toggle {
  text-align: right;
  /* border: 1px solid red; */
}

.icon-toggle {
  width: 2rem;
  padding: 1.5rem 1.5rem 1.5rem 0;
  /* height: 2rem; */
}

.app-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-footer {
  /* background-color: white; */
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  /* height: 50vh; */
  text-align: center;
  padding: 2rem 0;
}

.app-people {
  display: flex;
  font-size: 2rem;
  overflow: scroll;
  width: 100%;
  display: flex;
  justify-content: center;
}

.app-people>button {
  display: inline-block;
  font-size: 1.5rem;
  text-align: center;
  width: .5rem;
  padding: .5rem;
}

.app-link {
  color: #61dafb;
}

/* KEYFRAMES */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}