.asset-body {
  display: flex;
  width: 24rem;
  height: 24rem;
  /* border: 1px solid red; */
  margin: .5rem 0 0 0;
  justify-content: space-between;
  flex-direction: column;
}

.asset-title {
  font-size: 2.5rem;
  padding: 2.5rem 2.5rem 0;
  line-height: 40px;
  text-align: left;
  max-width: 400px;
}

.asset-emoji {
  text-align: right;
  padding: 0 1rem 1rem 0;
  font-size: 5rem;
  /* border: 1px solid red; */
}