.button-body {
  /* background-color: #000; */
  border-radius: 3rem;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  margin: .25rem;
  min-width: 5rem;
  padding: 1rem;
  border: 1px solid #000;
  margin: 4px;
  font-weight: bolder;
  outline: none;
}