.spin-button {
  background-color: #000;
  border-radius: 3rem;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  margin: .25rem;
  min-width: 5rem;
  padding: 1rem;
  border: none;
  font-weight: bolder;
  outline: none;
}